import { __decorate } from "tslib";
import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { resetRouter } from '@/router';
import { login } from '@/api/user';
import { getToken, setToken, removeToken } from '@/utils/cookies';
let User = class User extends VuexModule {
    constructor() {
        super(...arguments);
        this.token = getToken() || '';
        this.name = '';
        this.status = '';
        this.avatar = '';
        this.introduction = '';
        this.roles = '';
        this.email = '';
    }
    SET_TOKEN(token) {
        this.token = token;
    }
    SET_NAME(name) {
        this.name = name;
    }
    SET_STATUS(status) {
        this.status = status;
    }
    SET_AVATAR(avatar) {
        this.avatar = avatar;
    }
    SET_INTRODUCTION(introduction) {
        this.introduction = introduction;
    }
    SET_ROLES(roles) {
        this.roles = roles;
    }
    SET_EMAIL(email) {
        this.email = email;
    }
    async Login(userInfo) {
        let { username, password } = userInfo;
        username = username.trim();
        try {
            const { data } = await login({ 'Username': username, 'SecretKey': password, 'SecureTokenId': '1A553669' });
            setToken(data.Token);
            this.SET_TOKEN(data.Token);
            this.SET_NAME(data.UserName);
            return true;
        }
        catch (e) {
            return false;
        }
    }
    ResetToken() {
        //removeToken()
        this.SET_TOKEN('');
        this.SET_ROLES('');
    }
    async GetUserInfo() {
        if (this.token === '')
            throw Error('GetUserInfo: token is undefined!');
        //const { data } = await getUserInfo({ "roles" : "Admin", "name": "Demo", "avatar": "", "introduction" : "", "email" : ""})
        const data = ({ "roles": "Admin", "name": "Demo", "avatar": "", "introduction": "", "email": "" });
        if (!data)
            throw Error('Verification failed, please Login again.');
        const { roles, name, avatar, introduction, email } = data;
        // roles must be a non-empty array
        if (!roles || roles.length <= 0)
            throw Error('GetUserInfo: roles must be a non-null array!');
        this.SET_ROLES(roles);
        this.SET_NAME(name);
        this.SET_AVATAR(avatar);
        this.SET_INTRODUCTION(introduction);
        this.SET_EMAIL(email);
    }
    async LogOut() {
        if (this.token === '') {
            throw Error('LogOut: token is undefined!');
        }
        removeToken();
        resetRouter();
        this.SET_TOKEN('');
    }
};
__decorate([
    Mutation
], User.prototype, "SET_TOKEN", null);
__decorate([
    Mutation
], User.prototype, "SET_NAME", null);
__decorate([
    Mutation
], User.prototype, "SET_STATUS", null);
__decorate([
    Mutation
], User.prototype, "SET_AVATAR", null);
__decorate([
    Mutation
], User.prototype, "SET_INTRODUCTION", null);
__decorate([
    Mutation
], User.prototype, "SET_ROLES", null);
__decorate([
    Mutation
], User.prototype, "SET_EMAIL", null);
__decorate([
    Action
], User.prototype, "Login", null);
__decorate([
    Action
], User.prototype, "ResetToken", null);
__decorate([
    Action
], User.prototype, "GetUserInfo", null);
__decorate([
    Action
], User.prototype, "LogOut", null);
User = __decorate([
    Module({ dynamic: true, store, name: 'user' })
], User);
export const UserModule = getModule(User);
