import Vue from 'vue';
import Router from 'vue-router';
// const Layout = () => import(/* webpackChunkName: "components/layout" */ '@/components/Layout/index.vue')
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};
Vue.use(Router);
export const constantRoutes = [
    {
        path: '/redirect',
        component: () => import(/* webpackChunkName: "components/layout" */ '@/components/Layout/index.vue'),
        meta: { hidden: true },
        children: [
            {
                path: '/redirect/:path*',
                component: () => import(/* webpackChunkName: "components/views/redirect" */ '@/views/redirect/index.vue'),
            }
        ]
    },
    {
        path: '/login',
        name: 'PageLogin',
        component: () => import(/* webpackChunkName: "components/views/login" */ '@/views/login/index.vue'),
        meta: { hidden: true, title: "login" }
    },
    {
        path: '/',
        component: () => import(/* webpackChunkName: "components/layout" */ '@/components/Layout/index.vue'),
        redirect: '/dashboard',
        children: [{
                path: 'dashboard',
                component: () => import(/* webpackChunkName: "components/views/dashboard" */ '@/views/dashboard/index.vue'),
                name: 'Dashboard',
                meta: {
                    title: 'dashboard',
                    icon: 'dashboard',
                    affix: true
                }
            }]
    },
    {
        path: '*',
        redirect: '/error/404',
        meta: { hidden: true }
    },
    {
        path: '/error',
        component: () => import(/* webpackChunkName: "components/layout" */ '@/components/Layout/index.vue'),
        redirect: 'noredirect',
        meta: {
            title: 'errorPages',
            icon: '404'
        },
        children: [
            {
                path: '401',
                component: () => import(/* webpackChunkName: "components/views/error-page-401" */ '@/views/error/401.vue'),
                name: 'Page401',
                meta: {
                    title: 'page401',
                    noCache: true
                }
            },
            {
                path: '404',
                component: () => import(/* webpackChunkName: "components/views/error-page-404" */ '@/views/error/404.vue'),
                name: 'Page404',
                meta: {
                    title: 'page404',
                    noCache: true
                }
            }
        ]
    },
    {
        path: '/scan',
        name: 'Scanner',
        component: () => import(/* webpackChunkName: "components/views/scan" */ '@/views/scan/index.vue'),
        meta: { hidden: true, title: "Scanner" }
    }
];
const createRouter = () => new Router({
    mode: 'history',
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition)
            return savedPosition;
        else
            return { x: 0, y: 0 };
    },
    base: process.env.BASE_URL,
    routes: constantRoutes
});
const router = createRouter();
// router.beforeEach((to, from, next) => {
//   const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
//   if(nearestWithTitle) document.title = nearestWithTitle.meta.title;
//   //document.title = to.meta.title || 'Your Website Title';
//   next();
// });
export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher;
}
export default router;
