import Vue from "vue";
import App from '@/App.vue';
import Vuetify from '@/plugins/vuetify';
import router from '@/router';
import store from '@/store';
import i18n from '@/utils/language';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Meta from 'vue-meta';
import '@/permission';
import Element from 'element-ui';
Vue.config.productionTip = false;
Vue.use(Meta);
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(Element);
let v = new Vue({
    vuetify: Vuetify,
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount("#app");
