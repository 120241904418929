const getters = {
    sidebar: (state) => state.app.sidebar,
    language: (state) => state.app.language,
    size: (state) => state.app.size,
    device: (state) => state.app.device,
    visitedViews: (state) => state.tagsView.visitedViews,
    cachedViews: (state) => state.tagsView.cachedViews,
    token: (state) => state.user.token,
    avatar: (state) => state.user.avatar,
    name: (state) => state.user.name,
    introduction: (state) => state.user.introduction,
    status: (state) => state.user.status,
    roles: (state) => state.user.roles,
    tagsView: (state) => true,
};
export default getters;
