export default {
    route: {
        login: "Login",
        dashboard: "Dashboard",
        errorPages: 'Error',
        page401: "Error 401",
        page404: "Error 404",
        photos: "Photos",
        logout: 'Logout'
    },
    message: {
        success: "Success",
        username: "Username",
        password: "Password",
        login: "Login"
    },
    required: {
        username: "Required Username",
        password: "Required Password"
    },
    general: {
        close: "Close",
        closeAll: "Close All",
        closeOthers: "Close Others",
        refresh: "Refresh",
    }
};
